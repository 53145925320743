













































import { Component, Mixins } from 'vue-property-decorator'
import { Bind, Debounce } from 'lodash-decorators'

// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
// store
import AuthModule from '@/store/modules/auth'
import DictionaryModule from '@/store/modules/dictionary'
import ManagerDepositoryModule from '@/store/modules/manager/depository'
// components
import FilesList from '@/components/FilesList.vue'
import Tag from '@/components/_uikit/Tag.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'

@Component({
  components: {
    FilesList,
    Tag,
    TiptapEditor,
  },
})
export default class DepositoryCourseItemMaterialsView extends Mixins(NotifyMixin, PermissionsMixin) {

  private courseID = this.$route.params.courseID
  private materialID = this.$route.params.materialID

  private get breadcrumbs() {
    return [
      { name: 'База знаний', path: { name: 'manager.bank.depository' } },
      { name: this.course?.name, path: { name: 'manager.bank.depository.courses.item.materials', params: { courseID: this.course?.value } } },
    ]
  }

  private get course() {
    return ManagerDepositoryModule.course
  }

  private get material() {
    const material = ManagerDepositoryModule.currentMaterial
    if (material) {
      material.courses = material.courses.filter(course => course.value !== +this.courseID)
    }
    return material
  }

  private get materialTypes() {
    const types: { [key: string]: string } = {}
    DictionaryModule.usefulMaterialsTypes.map(type => types[type.value] = type.name)
    return types
  }

  private get isLocalTimezone() {
    return AuthModule.isLocalTimezone
  }

  private content = ''

  private mounted() {
    if (this.course && this.course.value !== +this.courseID || !this.course) {
      ManagerDepositoryModule.fetchCourse(+this.courseID)
        .catch((error) => {
          this.notifyError(error)
          this.$router.push({ name: 'manager.bank.depository.courses' })
        })
    }
    this.fetchMaterial()
  }

  private destroyed() {
    this.content = ''
  }

  private editMaterial() {
    this.$router.push({
      name: 'manager.bank.depository.courses.item.materials.edit',
      params: { courseID: this.courseID, materialID: this.materialID },
    })
  }

  @Bind
  @Debounce(300)
  private fetchMaterial() {
    ManagerDepositoryModule.fetchMaterial(+this.materialID)
      .then(response => this.content = JSON.parse(response.content))
      .catch(this.notifyError)
  }

}
